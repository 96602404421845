<template>
    <vx-card class="pb-10">
        <div class="vx-row">
            <!-- titulo -->
            <div class="vx-col w-full">
                <h2>Carga Masiva de Zonas de Tiendas</h2>
            </div>

            <!-- carga de archivos -->
            <div class="vx-col lg:w-1/2 sm:w-full mt-10">
                <input type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" ref="file" accept=".zip" @change="onChange()" />

                <div
                    class="border-2 border-dashed text-center pt-24 pb-24 mt-5 cursor-pointer rounded-lg"
                    @click="clickFileButton()"
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @drop="drop"
                >
                    <img :src="require(`@/assets/icons/CloudUploadIcon.svg`)" width="50px" class="inline-block align-middle"/><br/><br/>
                    <p>Carga tu archivo</p>
                    <span>Archivos Soportados:</span><br/>
                    <span>.zip</span><br/><br/>
                    <vs-button color="#daf7fd" text-color="#22bad6" class="rounded-full">
                        Cargar Archivo
                    </vs-button>
                </div>
            </div>

            <!-- lectura de archivos -->
            <div class="vx-col lg:w-1/2 sm:w-full mt-10">
                
                <!-- lista de archivos -->
                <VuePerfectScrollbar :settings="settings" style="height: 85%">
                    <h4>Archivos subidos</h4>

                    <vx-card v-if="files.length === 0" class="text-center mt-5 pt-2 pb-2">
                            <img :src="require(`@/assets/icons/CloudUploadIcon.svg`)" width="40px" class="inline-block align-middle mr-5"/>
                            <span class="align-middle text-thin">
                                No se encontraron archivos subidos
                            </span>   
                    </vx-card>

                    <vx-card v-else class="mt-5" v-for="(file, i) in files" v-bind:key="i">
                        <div class="vx-row">
                            <div class="vx-col w-auto">
                                <img :src="require(`@/assets/icons/ZipFileIcon.svg`)" width="64px" />
                            </div>

                            <div class="vx-col w-5/6">
                                <vs-button color="transparent" text-color="#000" size="small" class="float-right" @click="deleteFile(i)">
                                    <feather-icon icon="XIcon" />
                                </vs-button>

                                <h3>{{ file.file.name }}</h3>
                                <span>Subido {{ file.progress }}%</span>
                                <vs-progress :height="12" :percent="file.progress" :color="file.error > 0 ? 'danger' : 'success'"></vs-progress>
                                <p v-if="file.error" class="text-danger"> {{ file.error }} </p>
                            </div>
                        </div>
                    </vx-card>

                </VuePerfectScrollbar>

                <!-- boton guardar -->
                <div class="w-full mt-5">
                    <button class="vs-component vs-button rounded-full vs-button-primary vs-button-filled float-right" :disabled="files.length === 0" @click="uploadFiles()">
                        Cargar Archivos
                    </button>
                </div>
            </div>

        </div>
    </vx-card>
</template>

<script lang="ts">
import Vue from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default Vue.extend({
    data() {
        return {
            files: [],
            settings: { // perfectscrollbar settings
                maxScrollbarLength: 60,
                wheelSpeed: .60,
            },
        }
    },

    watch: {
        files: {
            handler( val ) {
                const uploaded = val.filter( f => f.progress === 100 );
                const fileErrs = val.filter( fe => fe.error.length > 0 );
                if( uploaded.length === this.files.length && fileErrs.length === 0 ) this.$router.push( { path : '/app/stores' } );
            },
            deep: true,
        }
    },

    methods: {
        getFileFormat( filename ) {
            return filename.substr(filename.lastIndexOf('.') + 1);
        },

        onChange() {
            const file = this.$refs.file.files[0];
            const filename = file.name;
            const ext = this.getFileFormat(filename);
            
            if( ext !== 'zip'  ) {
                return this.$vs.notify({
                    title: 'Formato Incorrecto',
                    text: 'Cargue archivos .zip solamente',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
            }

            const fileMapped = [...this.$refs.file.files].map( file => {
                let ob = Object.assign({}, file);
                ob.file     = file;
                ob.progress = 0;
                ob.error    = '';
                ob.action   = "stores/uploadZones";
                return ob;
            });

            fileMapped.forEach( file => {
                this.files.push(file);
            });           
            
        },

        clickFileButton() {
            this.$refs.file.click();
        },

        dragover( ev ) {            
            ev.preventDefault();
            
            if (!ev.currentTarget.classList.contains('bg-primary')) {
                ev.currentTarget.classList.remove('bg-gray-100');
                ev.currentTarget.classList.add('bg-primary');
            }
        },

        dragleave( ev ) {
            ev.currentTarget.classList.remove('bg-primary');
        },

        drop( ev ) {
            ev.preventDefault();
            this.$refs.file.files = ev.dataTransfer.files;
            this.onChange();
            ev.currentTarget.classList.remove('bg-primary');
        },

        deleteFile( index ) {
            this.files.splice( index, 1 );
        },

        uploadFiles() {
            this.$store.dispatch( 'firebase/uploadFilesToStorage', this.files )
                .then( () => {
                    this.$vs.notify({
                        title: 'Se están cargando los archivos',
                        text: 'Verifica su análisis en el menú de notificaciones',
                        iconPack: 'feather',
                        icon: 'icon-check',
                        color: 'primary',
                        time: 7000,
                    });
                });
        },
    },

    components: {
        VuePerfectScrollbar,
    }

})
</script>
